.rs-picker-toggle .rs-picker-toggle-placeholder {
  font-size: 12px !important;
  font-family: "Roboto" !important;
  color: #344054 !important;
  line-height: 14.4px !important;
  font-weight: 400 !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  font-size: 12px !important;
  font-family: "Roboto" !important;
  color: #344054 !important;
  font-weight: 400 !important;
}

.rs-picker-default .rs-picker-toggle {
  border-radius: 4;
  border: 1px solid #d0d5dd;
}

.rs-picker-toggle-caret,
.rs-picker-toggle-clean {
  color: #344054 !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rs-calendar-table-cell-in-range:before {
  background-color: #4b738145 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #3a4d54;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px #3a4d5482;
}

.rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  background-color: #3a4d541a !important;
  color: black !important;
}

.rs-btn-primary,
.rs-btn-primary:focus,
.rs-btn-primary:hover {
  background-color: #6aaf68;
}

.rs-picker-toggle.rs-btn {
  border-color: #3b4f5657;
  box-shadow: none;
}

.rs-picker-toggle.rs-btn:hover,
.rs-picker-toggle:focus {
  border-color: #3b4f5657 !important;
}
