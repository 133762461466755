ul.control-dots {
    position: absolute !important;
}

.carousel-status, .carousel .thumbs-wrapper {
    display: none;
}

.dot.selected {
    background-color: rgb(73, 73, 73) !important;
}

.dot {
    background-color: rgb(175, 175, 175) !important;
}