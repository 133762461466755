.fade-in-out {
    animation: fadeinout infinite 2.5s;
    -webkit-animation: fadeinout infinite  2.5s;
    -moz-animation: fadeinout infinite  2.5s;
    -o-animation: fadeinout infinite  2.5s;
    -ms-animation: fadeinout infinite  2.5;
  }
  
  @-webkit-keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  @keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  @-moz-keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
  @-webkit-keyframes  fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  @-o-keyframes  fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  @-ms-keyframes  fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
  