body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E5E5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body:not(#login) .grecaptcha-badge {
  visibility: hidden;
}

a {
  text-decoration: none;
}
/* This css remove the default show password icon in microsoft edge */
input::-ms-reveal, input::-ms-clear {
  display: none;
}

.rs-picker-date-menu {
  z-index: 1002 !important;
}
