.tooltip__container {
    background-color: #FFFFFF;
    border: 1px solid #F2F4F7;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(124,141,181, 0.12);
}

.tooltip__header {
    background: #F9FAFB;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip__title {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #98A2B3;
}

.tooltip__content {
    display: flex;
    align-items: center;
    padding: 4px 20px;
    justify-content: space-between;
    gap: 44px;
}

.tooltip__labels_container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.tooltip__marker {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.tooltip__label {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 14.4px;
    color: #101828
}

.tooltip__value {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    color: #101828;
}