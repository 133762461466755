/* Style the brush */
.brush .overlay {
    fill: none;
    pointer-events: all;
  }
  
  .brush .selection {
    fill: rgba(119, 119, 119, 0.8) !important;
  }
  
  .brush .handle {
    fill: rgba(119, 119, 119, 0.8) !important;
    stroke: #000;
  }
  